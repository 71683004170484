@import url("https://cdn.jsdelivr.net/gh/lipis/flag-icons@main/css/flag-icons.min.css");

body {
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 100vh;
}